import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ScaleLoader from "react-spinners/ScaleLoader";

import styled from 'styled-components';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { Link } from 'gatsby';

import { Input } from '../Form';
import Alert from '../Alert';

const SignInFormTitle = styled(Heading)`
  height: 26px;
  margin-top: 42px;
  margin-bottom: 16px;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.04;
  letter-spacing: 2.4px;
  color: ${props => props.theme.colors.blues.peacock};
`;

const SignInForm = ({ message, loadingIndicator }) => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" flexWrap="wrap">
      <SignInFormTitle>{t('sign_in.form.title')}</SignInFormTitle>
      <Alert message={message} />
      <Input
        title={t('sign_in.form.email')}
        name="username"
        type="email"
        ref={register({ required: true })}
      />
      <Input
        title={t('sign_in.form.password')}
        name="password"
        type="password"
        ref={register({ required: true })}
      />
      <Flex mt="23px" alignItems="center" flexWrap="wrap">
        <Box width={[1, 1 / 2, 1 / 2]}>
          <Link to="/account_recovery">
            <Text
              fontSize="13px"
              color="blues.peacock"
              css={{ lineHeight: 1.58, letterSpacing: '0.6px' }}
            >
              {t('sign_in.form.forgot')}
            </Text>
          </Link>
        </Box>
        {!loadingIndicator &&
          <Button
            width={[1, 1 / 2, 1 / 2]}
            ml="auto"
            py="8px"
            type="submit"
            fontSize="14px"
            fontWeight="bold"
            bg="blues.peacock"
            css={{
              lineHeight: 1.5,
              letterSpacing: '2.34px',
              borderRadius: '4px',
            }}
          >
            {t('sign_in.form.button')}
          </Button>
        }
        {loadingIndicator && 
          <Box
          width={[1, 1 / 2, 1 / 2]}
          textAlign="center"> 
            <ScaleLoader
              css={{}}
              size={40}
              color={"#01549b"}
              loading={loadingIndicator}
            />
          </Box>
        }
      </Flex>
    </Flex>
  );
};

export default SignInForm;
