import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useIdentityContext } from 'react-netlify-identity';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';

import { Link } from '../components/Link';
import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Hide } from '../components/hide';
import { Header } from '../components/Header';
import Form from '../components/Form';
import SignInForm from '../components/SignInForm';
import { EasySteps } from '../components/EasySteps';
import { swiperParams } from '../theme';
import i18n from '../i18n';

import SignInBlueTriangle from '../icons/signin-blue-triangle.svg';
import SignInBackground from '../images/signin-image-2@2x.jpg';
import { useGlobalContext } from '../GlobalState';

const SignUpCard = props => (
  <Card my="30px" borderRadius="20px" bg="#fff5c7" {...props} />
);

const SignUpTitle = props => (
  <Text
    {...props}
    mt="33px"
    ml="28px"
    fontSize="25px"
    lineHeight="1.04"
    letterSpacing="2.4px"
    color="blues.peacock"
  />
);

const SignUpSubTitle = props => <Box {...props} mt="22px" bg="maize" />;

const SignUpSubTitleText = props => (
  <Heading
    {...props}
    mt="22px"
    mb="16px"
    ml="28px"
    fontSize="30px"
    lineHeight="0.83"
    color="blues.medium"
  />
);

const SignUpParagraph = styled.p`
  margin-top: 16px;
  margin-left: 28px;
  margin-right: 43px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.84;
  letter-spacing: 0.7px;
  color: ${props => props.theme.colors.marine};
`;

const ERROR_DESCRIPTIONS = {
  'No user found with this email': 'validation.invalid_username_or_password',
  'Invalid Password': 'validation.invalid_username_or_password',
  'Email not confirmed': 'validation.invalid_grant',
};

export const GET_USER = gql`
  query FindAccountByID($accountId: ID!) {
    findAccountByID(id: $accountId) {
      _id
      type
      isEnabled
      companyInfo {
        companyName
        vat
        taxOffice
        POBox
      }
    }
  }
`;

export default function SignInPage(props) {
  const [alertMsg, setAlertMsg] = useState(null);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const { loginUser } = useIdentityContext();
  const { user, setUser } = useGlobalContext();
  const { t } = useTranslation();

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setUser(data.findAccountByID);
      setLoadingIndicator(false);
      navigate(
        i18n.language === 'en'
          ? `${'/' + i18n.language}/my_boxit/summary`
          : '/my_boxit/summary'
      );
    },
    onError: err => {
      setLoadingIndicator(false);
      setAlertMsg('Error retrieving user');
    },
  });

  const handleSubmit = async data => {
    setLoadingIndicator(true);
    try {
      const res = await loginUser(data.username, data.password);
      //setLoadingIndicator(false)
      if (!res) {
        setAlertMsg(t('validation.invalid_username_or_password'));
        return false;
      }
      getUser({
        variables: {
          accountId: res.user_metadata.aid,
        },
      });
      //navigate((i18n.language === 'en')? `${'/'+i18n.language}/my_boxit/summary`: '/my_boxit/summary');
      return true;
    } catch (e) {
      setLoadingIndicator(false);
      if (e.json && e.json.error === 'invalid_grant') {
        const errDescr =
          ERROR_DESCRIPTIONS[e.json.error_description] ||
          e.json.error_description;
        setAlertMsg(t(errDescr));
      } else {
        setAlertMsg(t(e));
      }
    }
  };

  return (
    <Layout {...props}>
      <SEO title="SIGN IN" keywords={['weboxit', 'boxit']} />
      <Header title={t('sign_in.title')} subtitle={t('sign_in.subtitle')} />
      <Container>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={['0', '12px', '62px']}>
            <Form onSubmit={handleSubmit}>
              <SignInForm
                loadingIndicator={loadingIndicator}
                message={alertMsg}
              />
            </Form>
          </Box>
          <SignUpCard width={[1, 1 / 2, 1 / 2, 1 / 2]}>
            <Flex flexDirection="column">
              <SignUpTitle>{t('sign_up.home.title')}</SignUpTitle>
              <SignUpSubTitle>
                <SignUpSubTitleText>
                  {/* {t('sign_up.home.subtitle')} */}
                  {t('sign_up.home.comming_soon')}
                </SignUpSubTitleText>
              </SignUpSubTitle>
              {/* <SignUpParagraph>{t('sign_up.home.content')}</SignUpParagraph> */}
              <SignUpParagraph>
                {t('sign_up.home.comming_soon_content')}
              </SignUpParagraph>
              {/* 
              <Link to="/sign_up">
                <Button
                  mx="30px"
                  mb="24px"
                  px="73px"
                  py="8px"
                  fontSize="14px"
                  fontWeight="bold"
                  bg="blues.peacock"
                  css={{
                    lineHeight: 1.5,
                    letterSpacing: '2.34px',
                    borderRadius: '4px',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  {t('sign_up.home.button')}
                </Button>
              </Link>
              */}
            </Flex>
          </SignUpCard>
        </Flex>
      </Container>

      <Hide mobile>
        {/* Tablet & Desktop */}
        <Container>
          <Card
            id="signInBackground"
            mt={[3, 6, 7]}
            backgroundImage={'url(' + SignInBackground + ')'}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            borderRadius="20px 20px 20px 20px"
          >
            <Card
              id="signInBlueTriangle"
              width={[2 / 5, 0.42, 0.42]}
              backgroundImage={'url(' + SignInBlueTriangle + ')'}
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="right center"
              borderRadius="20px 0px 0px 20px"
            >
              <Box
                pl={[3, '44px', '62px']}
                pr={[3, '44px', '110px']}
                py={[0, 0, 5]}
              >
                <Text
                  pt={['55px']}
                  pb={[3, 3, 4]}
                  color="white"
                  fontSize={['34px', '26px', '40px']}
                  fontWeight="bold"
                  lineHeight="1.27"
                >
                  {t('sign_in.the_easiest_way')}
                </Text>
                <Text
                  pt={[0, 2, 0]}
                  pb={[4, 3, 4]}
                  width={0.95}
                  color="white"
                  fontSize={['14px', '15px', '22px']}
                  letterSpacing="normal"
                  lineHeight="1.41"
                  textAlign="left"
                >
                  {t('sign_in.to_send_or_receive')}
                </Text>
                <Text
                  pt={[0, 2, 0]}
                  pb={[4, 3, 4]}
                  width={0.95}
                  color="white"
                  fontSize={['14px', '15px', '22px']}
                  letterSpacing="normal"
                  lineHeight="1.41"
                  textAlign="left"
                >
                  {t('sign_in.one_station_near_you')}
                </Text>
              </Box>
            </Card>
          </Card>
        </Container>
      </Hide>

      <EasySteps showHeader={false} params={{ swiperParams }} />
    </Layout>
  );
}
